import React, { memo, useRef, useState, useEffect } from 'react'
import { getLikes } from '../../services/likes'
import PropTypes from 'prop-types'
import SectionTitle from '../SectionTitle'
import BalloonButton from '../BalloonButton'
import BlogCard from './BlogCard'

import './HomeBlogSection.sass'
import { LIKE_TYPES } from '../../constants'

const HomeBlogSection = ({ data }) => {
  const sectionRef = useRef(null)
  const [isShowing, setShowing] = useState(false)
  const [likes, setLikes] = useState({})

  const fetchData = async (slugs) => {
    try {
      const data = await getLikes(slugs, LIKE_TYPES.BLOG)
      setLikes(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    // Get likes data
    const slugs = []
    for (const post of data) {
      if (post.node && post.node.slug) slugs.push(post.node.slug)
    }
    fetchData(slugs)
    if (sectionRef.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            setShowing(true)
            observer.unobserve(entry.target)
          } else {
          }
        })
      }, {
        threshold: 0.2
      })
      observer.observe(sectionRef.current)
    }
  }, [sectionRef])

  const _renderCards = () => {
    if (!likes || !data || data.length === 0) return null
    return data.map(({ node }) => {
      const { slug, title, date, modified, acf_blog: { excerpt, mainImage, tags } } = node
      return (
        <BlogCard
          key={slug}
          slug={slug}
          title={title}
          excerpt={excerpt}
          mainImage={mainImage}
          tags={tags}
          date={date}
          likes={(likes[slug] && likes[slug].count) || 0}
          like={(likes[slug] && likes[slug].like) || false}
          refetch={fetchData}
        />
      )
    })
  }

  return (
    <section className={`blogSection${isShowing ? '' : ' outsideVP'}`} ref={sectionRef}>
      <SectionTitle title="Blog" subtitle="ブログ"/>
      <div className="cards flex">
        { _renderCards() }
      </div>
      <div className="text-center">
        <BalloonButton label="もっと見る" to="/blog" jpText />
      </div>
    </section>
  )
}
export default memo(HomeBlogSection)

HomeBlogSection.propTypes = {
  data: PropTypes.array.isRequired
}
HomeBlogSection.defaultProps = {

}
