import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageWrapper from '../components/PageWrapper'
import SEO from '../components/SEO'
import HomeTopSection from '../components/HomeTopSection'
import HomeBlogSection from '../components/HomeBlogSection'
import HomeMSquareSection from '../components/HomeMSquareSection'
import HomeAboutSection from '../components/HomeAboutSection'

const HomePage = ({ data }) => {
  const blogData = data && data.allWpBlogPost && data.allWpBlogPost.edges && data.allWpBlogPost.edges.length > 0
    ? data.allWpBlogPost.edges
    : null
  return (
    <PageWrapper>
      <SEO title="Home" />
      <HomeTopSection />
      <HomeBlogSection data={blogData}/>
      <HomeMSquareSection />
      <HomeAboutSection />
    </PageWrapper>

  )
}

export default memo(HomePage)

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export const pageQuery = graphql`
    query homeQuery{
      allWpBlogPost(limit: 6, sort: {order: DESC, fields: date}, filter: {acf_blog: {featured: {eq: true}}}) {
        edges {
          node {
              id
              slug
              title
              date(formatString: "YYYY年MM月DD日")
              modified(formatString: "YYYY年MM月DD日")
              acf_blog {
                mainImage {
                  title
                  altText
                  sourceUrl
                }
                excerpt
                tags: blogTags {
                  name
                  slug
                  termTaxonomyId
                  description
                }
              }
          }
        }
      }
    }
`
