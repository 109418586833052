import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './FlippingImage.sass'
import Img from '../Img'

class FlippingImage extends PureComponent {
  state = {
    index: 0,
    isFlipped: false,
    frontImgIndex: 0,
    backImgIndex: 1,
    intervalId: null
  }

  componentDidMount () {
    const { images, interval = 3000, delay = 0 } = this.props
    const timeout = delay + (Math.random() * 301)

    if (images && images.length > 1) {
      setTimeout(() => {
        const intervalId = setInterval(this.flip, interval)
        this.setState({
          intervalId,
          frontImgIndex: 0,
          backImgIndex: 1
        })
      }, timeout)
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.intervalId)
  }

  flip = () => {
    const { index, isFlipped, frontImgIndex, backImgIndex } = this.state
    const { images } = this.props
    const imagesLength = images.length
    const newIndex = index < imagesLength - 1 ? index + 1 : 0
    const updateFront = () => {
      if (isFlipped) {
        return backImgIndex < imagesLength - 1 ? backImgIndex + 1 : 0
      } else {
        return frontImgIndex
      }
    }
    const updateBack = () => {
      if (isFlipped) {
        return backImgIndex
      } else {
        return frontImgIndex < imagesLength - 1 ? frontImgIndex + 1 : 0
      }
    }

    this.setState({
      isFlipped: !isFlipped,
      frontImgIndex: updateFront(),
      backImgIndex: updateBack(),
      index: newIndex
    })
  }

  render () {
    const { images, contain } = this.props
    const { frontImgIndex, backImgIndex } = this.state
    if (images && images.length > 0) {
      return (
        <div className={`flipContainer${this.state.isFlipped ? ' flip' : ''}`}>
          <div className="innerContainer">
            <div className={`front${contain ? ' contain' : ''}`}
              style={{
                backgroundImage: `url("${images[frontImgIndex]}")`
              }}
            >
            </div>
            <div className={`back${contain ? ' contain' : ''}`}
              style={{
                backgroundImage: `url("${images[backImgIndex]}")`
              }}
            >
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

export default FlippingImage

FlippingImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.number,
  contain: PropTypes.bool,
  delay: PropTypes.number
}
FlippingImage.defaultProps = {

}
