import React, { memo, useRef, useState, useEffect } from 'react'
import Img from '../Img'
import FlippingImage from '../FlippingImage'

import BalloonButton from '../BalloonButton'

import mamasquare_logo from '../../assets/img/msquare/logo/mamasquare_logo.jpg'
import msquare_logo from '../../assets/img/msquare/logo/msquare_logo_hp.jpg'
import msquareBg from '../../assets/img/top_msquare_bg.jpg'
import msquareImg from '../../assets/img/top_msquare_img.jpg'
import './HomeMSquareSection.sass'

const HomeMSquareSection = () => {
  const sectionRef = useRef(null)
  const [isShowing, setShowing] = useState(false)

  useEffect(() => {
    // console.log(sectionRef.current)
    if (sectionRef.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            setShowing(true)
            observer.unobserve(entry.target)
          } else {
          }
        })
      }, {
        threshold: 0.2
      })
      observer.observe(sectionRef.current)
    }
  }, [sectionRef])

  return (
    <section className={`msquareSection${isShowing ? '' : ' outsideVP'}`} ref={sectionRef}>
      <div className="msquareIntroduction flex">
        <div className="left">
          <h1 className="accent text-center">M² Square</h1>
          <h2 className="jp-accent text-center regular">ママスクエア</h2>
          <div className="flippingLogoContainer">
            <FlippingImage images={[mamasquare_logo, msquare_logo]} contain delay={0} />
          </div>
          {/* <Img src={msquareLogo} containerClass="logoContainer" contain alt="エムスクエアのロゴ" title="エムスクエア"/> */}
        </div>
        <div className="right column justify-center">
          <p className="large jp-accent text-center">
          作り手の顔が見える商品を使いたいお客様と、
            <br />
          仕立てスキルを活かして活躍するママがつながる。
          </p>
          <p className="medium jp-accent text-center">
          色鉛筆画をベースにmynaがデザインした生地を使い、
            <br />
          ママが手作りしてくれたような「温もりのあるもの」を仕立てます。
            <br />
          少量生産、Made in Japanの
            <br />
          《忙しいママを、ママの力で応援する》ブランドです。
          </p>
        </div>
      </div>
      <div className="text-center msquareMore">
        <BalloonButton label="もっと見る" to="/msquare" jpText />
      </div>
    </section>
  )
}
export default memo(HomeMSquareSection)
