import React, { memo } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import BalloonSVG from './BalloonSVG'
import './BalloonButton.sass'

const BalloonButton = ({ label, to, handleClick, jpText, small }) => {
  const textClass = jpText ? ' jp-accent' : ' accent'
  const sizeClass = small ? ' btn-small' : ''
  const renderLabel = () => {
    return (
      <>
        <div className={`label text-red${textClass}`}>{label}</div>
        <div className="balloon">
          <div className="svgContainer">
            <BalloonSVG />
          </div>
        </div>
      </>
    )
  }
  if (handleClick) {
    return (
      <button className={`balloonButton${sizeClass}`} onClick={handleClick}>
        { renderLabel() }
      </button>
    )
  }
  return (
    <Link className={`balloonButton${sizeClass}`} to={to}>
      { renderLabel() }
    </Link>
  )
}
export default memo(BalloonButton)

BalloonButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: (props, propName, componentName) => {
    if (!props.to && !props.handleClick) {
      return new Error(`One of props 'to' or 'handleClick' was not specified in '${componentName}'.`)
    }
    if (props.to && typeof props.to !== 'string') {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected a String but got ${typeof props.to}.`)
    }
  },
  handleClick: (props, propName, componentName) => {
    if (!props.to && !props.handleClick) {
      return new Error(`One of props 'to' or 'handleClick' was not specified in '${componentName}'.`)
    }
    if (props.handleClick && typeof props.handleClick !== 'function') {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Expected a Function but got ${typeof props.handleClick}.`)
    }
  }
}
BalloonButton.defaultProps = {

}
