import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Img from '../Img'

import NoImg from '../../assets/img/no_img.svg'
import Like from '../Like'
import { LIKE_TYPES } from '../../constants'

const BlogCard = ({
  slug,
  title,
  excerpt,
  mainImage,
  tags,
  date,
  likes,
  like,
  refetch
}) => {
  const imageUrl = mainImage ? mainImage.sourceUrl : NoImg

  const _handleCardClick = (e) => {
    e.stopPropagation()
    navigate(`/blog/${slug}`)
  }
  const _renderTags = () => {
    if (tags && tags.length > 0) {
      return tags.map(tag => {
        return <span key={tag.slug}>{`#${tag.name} `}</span>
      })
    }
    return null
  }
  return (
    <div className="blogLink" onClick={_handleCardClick}>
      <article className="blogCard">
        <div className="innerCard">
          <div className="front"
            style={{
              backgroundImage: `url("${imageUrl}")`
            }}
          >
          </div>
          <div className="back flex">
            <div className="top">
              <div className="cardImage">
                <Img src={imageUrl} alt={mainImage && mainImage.altText} title={mainImage && mainImage.title}/>
              </div>
            </div>
            <div className="bottom">
              <h2 className="medium">{title}</h2>
              <div className="date smaller jp-accent">{date}</div>
              <div className="ellipsis">
                <div>
                  <p className="small">
                    {excerpt}
                  </p>
                </div>
              </div>
              <div className="cardFooter flex justify-between">
                <div className="tags small">{_renderTags()}</div>
                <Like
                  slug={slug}
                  likes={likes}
                  like={like}
                  type={LIKE_TYPES.BLOG}
                />
              </div>
            </div>

          </div>
        </div>
      </article>
    </div>
  )
}

export default memo(BlogCard)

BlogCard.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  main_image: PropTypes.object,
  tags: PropTypes.array,
  date: PropTypes.string,
  likes: PropTypes.number,
  like: PropTypes.bool,
  refetch: PropTypes.func.isRequired
}
