import React, { memo, useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import './HomeAboutSection.sass'

import Img from '../Img'

import mynaLogo from '../../assets/img/myna.png'
import twitterLogo from '../../assets/img/twitter.svg'
import facebookLogo from '../../assets/img/facebook.svg'
import instagramLogo from '../../assets/img/instagram.svg'
import BalloonButton from '../BalloonButton/BalloonButton'

const HomeAboutSection = () => {
  const sectionRef = useRef(null)
  const [isShowing, setShowing] = useState(false)

  useEffect(() => {
    // console.log(sectionRef.current)
    if (sectionRef.current) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            setShowing(true)
            observer.unobserve(entry.target)
          } else {
          }
        })
      }, {
        threshold: 0.2
      })
      observer.observe(sectionRef.current)
    }
  }, [sectionRef])

  return (
    <section className={`aboutSection flex${isShowing ? '' : ' outsideVP'}`} ref={sectionRef}>
      <div className="left">
        <h1 className="accent text-red">About</h1>
        <p>名古屋を拠点とするデザイン屋、商業美術家（デザイナー 兼 色ストレーター）。</p>
        <p>
          マーケティング畑の経験を活かし、小さなお店のデザイン営業部として
          <br/>
          名刺やショップカードなどの〈紙もの〉のデザインを承っている。
          <br/>
          世界観を伝えるデザインを得意とする。
        </p>
        <p>
          2019年、ベビーブランド〈エムスクエア〉を立ちあげる。
        </p>
        <p>
          2020年、作り手とお客様がフェアな関係となる〈ママスクエア〉を立ちあげ、
          <br/>
          仕立てスキルのある【在宅仕立てママ】に制作の依頼、サポートを行っている。
        </p>
        <p>
          自分の世界観を色えんぴつで表現し、気まぐれで個展を開催。
        </p>
        <p>
          子育て中のママたちの役に立てば、という想いから、絵本制作を始める。
          <br/>
          2020年7月1日、絵本『NIPPONのぱん』を発売。絵本の詳細は<Link to="/work/bread-book" className="link link--red">コチラ</Link>から。
          <br/>
          2021年10月1日、絵本『NIPPONのおいしいきのこ』を発売予定。
        </p>
        <p>
          私生活では夫一人、子一人。フランス、時々名古屋。
        </p>

        <div className="contact">
          <div className="medium">
            <span>ご依頼・お問い合わせは</span>
            <Link to="/contact" className="contactLink link link--red">こちら</Link>
            <span>から</span>
          </div>
          {/* <div className="text-center">
            <BalloonButton label="Contact" to="/contact" />
          </div> */}
        </div>
      </div>
      <div className="right">
        <Img src={mynaLogo} containerClass="mynaLogo" contain alt="まいなの風船イラスト" title="まいな"/>
        <a href="https://twitter.com/myna_time" className="snsLogo twitterLogo" target="_blank" rel="noopener noreferrer">
          <Img src={twitterLogo} alt="Twitterへの風船アイコン" title="Twitterへ"/>
        </a>
        <a href="https://www.facebook.com/mynatime/" className="snsLogo facebookLogo" target="_blank" rel="noopener noreferrer">
          <Img src={facebookLogo} alt="Facebookへの風船アイコン" title="Facebookへ"/>
        </a>
        <a href="https://www.instagram.com/myna_time/" className="snsLogo instagramLogo" target="_blank" rel="noopener noreferrer">
          <Img src={instagramLogo} alt="Instagramへの風船アイコン" title="Instagramへ"/>
        </a>
      </div>

    </section>
  )
}
export default memo(HomeAboutSection)
