import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Img from '../Img'
import FlippingImage from '../FlippingImage/'
import logoBlack from '../../assets/img/logo_black.svg'

import citrus from '../../assets/img/top/topLeft/citrus.jpg'
import kuroemame from '../../assets/img/top/topLeft/kuroemame.jpg'
import salade from '../../assets/img/top/topLeft/salade.jpg'
import tomates from '../../assets/img/top/topLeft/tomates.jpg'
import wappan from '../../assets/img/top/topLeft/wappan.jpeg'

import bavoir from '../../assets/img/top/topRight/bavoir.jpg'
import brand from '../../assets/img/top/topRight/brand.jpg'
import dinos from '../../assets/img/top/topRight/dinos.jpeg'
import friends from '../../assets/img/top/topRight/friends.jpg'

import handbook from '../../assets/img/top/bottomLeft/handbook.jpg'
import tshirt from '../../assets/img/top/bottomLeft/tshirt.jpg'
import tshirt2 from '../../assets/img/top/bottomLeft/tshirt2.jpg'
import vegetables from '../../assets/img/top/bottomLeft/vegetables.jpg'
import pencils from '../../assets/img/top/bottomLeft/pencils.jpg'

import book from '../../assets/img/top/bottomRight/book.jpg'
import book2 from '../../assets/img/top/bottomRight/book2.jpg'
import business_card from '../../assets/img/top/bottomRight/business_card.jpg'
import cake from '../../assets/img/top/bottomRight/cake.jpg'
import magnet from '../../assets/img/top/bottomRight/magnet.jpg'

import './HomeTopSection.sass'

const HomeTopSection = () => {
  return (
    <section className="topSection">
      <div className="landing flex">
        <div className="left column justify-between">
          <div className="logoContainer">
            <Img src={logoBlack} alt="まいなタイムのロゴ" title="まいなタイム"/>
          </div>
          <p className="jp-accent medium">
            Myna Time。名古屋から
            <br />
            デザイナーMynaの日常の「時」をお届けします。
          </p>
        </div>
        <div className="right">
          <div className="topRow flex">
            <div className="cardContainer">
              <FlippingImage
                images={[
                  citrus,
                  kuroemame,
                  salade,
                  tomates,
                  wappan
                ]}
                interval={6000}
                delay={100}
              />
            </div>
            <div className="cardContainer">
              <FlippingImage
                images={[
                  bavoir,
                  brand,
                  dinos,
                  friends
                ]}
                interval={5800}
                delay={200}
              />
            </div>
          </div>
          <div className="bottomRow flex">
            <div className="cardContainer">
              <FlippingImage
                images={[
                  handbook,
                  tshirt,
                  tshirt2,
                  vegetables,
                  pencils
                ]}
                interval={6200}
                delay={50}
              />
            </div>
            <div className="cardContainer">
              <FlippingImage
                images={[
                  book,
                  book2,
                  business_card,
                  cake,
                  magnet
                ]}
                interval={5900}
                delay={150}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="catch flex">
        <div className="left">
          <h1 className="accent">Myna Time</h1>
          <h2 className="jp-accent">まいなタイム</h2>
          <p className="jp-accent">
          「
            <span className="text-red">色</span>
          」を添えるデザインで、
            <br />
           生活に豊かさを。
          </p>
        </div>
        <div className="right jp-accent column justify-center medium">
          <p>
            目指すのは、お客様の世界観、
            <br/>
            「
            <span className="text-red">色</span>
            」を引き出すデザイン。
          </p>
          <p>
            色鉛筆画を描く際も、アートとしてではなく、
            <br/>
            日常生活を彩るデザインになるよう心がけています。
          </p>
          <p>そんなMynaの日常の「時」を少し覗いてみてください。</p>
        </div>
      </div>
    </section>
  )
}
export default memo(HomeTopSection)

HomeTopSection.propTypes = {

}
HomeTopSection.defaultProps = {

}
